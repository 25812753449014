import { useEffect, useMemo, useState } from 'react';
import { EventStage, Media } from '@decub8/ui';
import { commify, formatEther, formatUnits } from '@ethersproject/units';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';

import { useProjectContext } from '@src/components/Project/context';
import { isHardcapMet } from '@src/components/Project/Event/utils';
import {
    BASE_IMAGE_URL,
    CONTENT,
    CONTRACT,
    DEFAULT_CHAIN_ID,
    NETWORKS,
} from '@src/config';
import { IDO_REG_CUTOFF_MINS } from '@src/constants';
import { useAppSelector, useCountdown } from '@src/hooks/index';
import { useGlobalContext } from '@src/hooks/useGlobalContext';
import { EventChipStatus, EventType } from '@src/ts/constants';
import { Project } from '@src/ts/interfaces';
import { generateSlug } from '@src/utils/format';
import { getEventEndDate, getMainEvent } from '@src/utils/getters';
import { parseBalance } from '@src/utils/web3';

import {
    getChips,
    getEventStage,
    getFormattedTimeLeft,
    isAmaEventFinished,
    isAmaEventLive,
    isDurationInThePast,
    isWithin60SecondsBefore,
} from './utils';

export const useMainEventCard = (project: Project) => {
    const {
        id,
        hero,
        name: project_name,
        description,
        event_status,
        social_platforms,
    } = project || {};

    const {
        start_date,
        crowdfunding_type,
        chainId,
        name: event_name,
        saft_url,
        marketing_event,
        ama_event,
        event_details: {
            durations = [],
            min_tier = 0,
            vesting = { start: '' },
        } = {},
        id: event_id,
        total_allocation,
        refund_deadline,
    } = project?.events[0] || {};

    const project_slug = generateSlug(project_name);

    const router = useRouter();
    const { symbol: payment_symbol, decimals: payment_decimals } =
        CONTRACT.PaymentToken[chainId];
    const { user } = useAppSelector((state) => state.auth);
    const {
        registerForWhitelist,
        num_whitelisted,

        user_allocation,
        whitelist_loading,
        registered,
        crowdfunding: { user_investment, total_raised, hardcap } = {
            user_investment: '0',
            hardcap: '0',
            total_raised: '0',
        },
    } = useProjectContext();

    const { _userTier, _setTierDrawerOpen, loadProjects } = useGlobalContext();

    const has_correct_tier = user ? _userTier?.id >= min_tier : false;

    // use 1 if hardcap === 0 as 0/0 === NaN
    const denom = Number(formatEther(hardcap)) || 1;
    const percent_filled = (Number(formatEther(total_raised)) / denom) * 100;

    const raised_stats = {
        total: `${parseBalance(
            total_raised,
            payment_decimals,
            2,
            true,
        )} ${payment_symbol}`,
        target: `${parseBalance(
            hardcap,
            payment_decimals,
            2,
            true,
        )} ${payment_symbol}`,
        percentage: percent_filled,
    };

    const is_hardcap_met = useMemo(
        () => isHardcapMet(payment_decimals, hardcap, total_raised),
        [payment_decimals, hardcap, total_raised],
    );

    const stage = getEventStage(project?.events[0], is_hardcap_met);

    const chips = useMemo(() => {
        return getChips(crowdfunding_type, event_status);
    }, [crowdfunding_type, event_status, stage]);

    const props_same = {
        stage,
        title: project_name,
        image: `${BASE_IMAGE_URL}/${id}/${hero}`,
        description: description,
        company_name: CONTENT.companyName,
        company_logo: CONTENT.logo.mobile,
        protected_logo:
            'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/Protected-Green.svg',
        saft_url,
        terms_url: CONTENT.termsAndConditions,
        network_info: {
            logo: NETWORKS[chainId || DEFAULT_CHAIN_ID]?.network_logo,
            name: NETWORKS[chainId || DEFAULT_CHAIN_ID]?.network_name,
        },
        chips,
    };

    const event_slug = generateSlug(event_name || '');

    const start = useMemo(() => dayjs(Number(start_date) * 1000), [start_date]);
    const end_date = useMemo(
        () => getEventEndDate(project?.events[0]),
        [project],
    );

    const cutoff = useMemo(
        () => start.subtract(IDO_REG_CUTOFF_MINS, 'minutes'),
        [start],
    );

    const ga_round_end = start.add(durations[0], 'seconds');
    const ga_round_over = dayjs().isAfter(ga_round_end);

    const vesting_start = vesting.start
        ? dayjs(Number(vesting.start) * 1000)
        : undefined;

    const time_left_to_ga_end = getFormattedTimeLeft(
        useCountdown(ga_round_end.toISOString()),
    );

    const time_to_event_end = getFormattedTimeLeft(
        useCountdown(end_date.toISOString()),
    );

    const time_left_to_vesting_start = getFormattedTimeLeft(
        useCountdown(vesting_start?.toISOString()),
    );

    const has_vesting_start_passed = isDurationInThePast(
        useCountdown(vesting_start?.toISOString()),
    );

    // update project data when the hardcap is met
    useEffect(() => {
        if (is_hardcap_met) loadProjects();
    }, [is_hardcap_met]);

    // set up timers for the updates
    useEffect(() => {
        // return;
        const now = dayjs();
        const is_after_cutoff = now.isAfter(cutoff);
        const is_after_start = now.isAfter(start);
        const is_after_end = now.isAfter(end_date);

        let cutoff_handler: NodeJS.Timeout;
        let start_handler: NodeJS.Timeout;
        let end_handler: NodeJS.Timeout;

        if (!is_after_cutoff)
            cutoff_handler = setTimeout(
                loadProjects,
                Math.abs(now.diff(cutoff, 'ms')),
            );

        if (!is_after_start)
            start_handler = setTimeout(
                loadProjects,
                Math.abs(now.diff(start, 'ms')),
            );

        if (!is_after_end)
            end_handler = setTimeout(
                loadProjects,
                Math.abs(now.diff(end_date, 'ms')),
            );

        return () => {
            clearTimeout(cutoff_handler);
            clearTimeout(start_handler);
            clearTimeout(end_handler);
        };
    }, [cutoff, start, end_date]);

    switch (stage) {
        case EventStage.PreWhitelist:
            return {
                data:
                    !ama_event && marketing_event
                        ? [
                              {
                                  label: 'Raise currency',
                                  value: payment_symbol,
                              },
                              {
                                  label: 'Event network',
                                  value: NETWORKS[chainId || DEFAULT_CHAIN_ID]
                                      ?.network_name,
                              },
                          ]
                        : [
                              {
                                  label: 'Whitelisted participants',
                                  value: 'TBA',
                              },
                              {
                                  label: 'Total allocation',
                                  value: `${total_allocation || 'XXX,XXX'} USD`,
                              },
                              { label: 'Your max allocation', value: 'TBA' },
                              {
                                  label: 'Event network',
                                  value: NETWORKS[chainId || DEFAULT_CHAIN_ID]
                                      ?.network_name,
                              },
                          ],
                ama_event:
                    ama_event &&
                    ama_event?.type &&
                    ama_event?.title &&
                    ama_event?.link &&
                    ama_event?.start_date &&
                    ama_event?.end_date
                        ? {
                              title: ama_event?.title,
                              date: dayjs(
                                  Number(ama_event?.start_date) * 1000,
                              ).format('D MMMM, H:mm [UTC]'),
                              youtube_url: ama_event?.link,
                              show_ama_live: isAmaEventLive(ama_event),
                              is_complete: isAmaEventFinished(ama_event),
                          }
                        : undefined,

                marketing_event_data: marketing_event
                    ? {
                          title: marketing_event?.title,
                          image: marketing_event?.image,
                          link: marketing_event?.link,
                      }
                    : undefined,
                socials: social_platforms.map(({ type, url }) => ({
                    name: type,
                    url,
                })),
                primary_button: {
                    text: 'View project',
                    handleClick: () =>
                        router.push(`/project/${project_slug}/${event_slug}`),
                },
                ...props_same,
            };
        case EventStage.WhitelistOpen:
            return {
                has_accent_countdown: isWithin60SecondsBefore(cutoff),
                target_date: cutoff.unix().toString(),
                text: 'Whitelist closes in',
                network_info: {
                    logo: NETWORKS[chainId || DEFAULT_CHAIN_ID]?.network_logo,
                    name: NETWORKS[chainId || DEFAULT_CHAIN_ID]?.network_name,
                },
                protected_logo:
                    'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/Protected-Green.svg',

                data: [
                    {
                        label: 'Whitelisted participants',
                        value: commify(num_whitelisted),
                    },
                    {
                        label: 'Total allocation',
                        value: `${total_allocation} USD`,
                    },
                    { label: 'Your max allocation', value: 'TBA' },
                    {
                        label: 'Event network',
                        value: NETWORKS[chainId || DEFAULT_CHAIN_ID]
                            ?.network_name,
                    },
                ],
                primary_button: user
                    ? {
                          text: registered
                              ? 'View event'
                              : has_correct_tier
                              ? 'Whitelist now'
                              : 'Upgrade your investor tier',
                          loading: whitelist_loading,
                          handleClick: () =>
                              registered
                                  ? router.push(
                                        `/project/${project_slug}/${event_slug}`,
                                    )
                                  : has_correct_tier
                                  ? registerForWhitelist(Number(event_id), [])
                                        .then(loadProjects)
                                        .catch(console.error)
                                  : _setTierDrawerOpen(true),
                      }
                    : {
                          text: 'Register or login to whitelist',
                          handleClick: () => router.push('/login'),
                      },
                secondary_button:
                    !registered || !user
                        ? {
                              text: 'View event',
                              handleClick: () =>
                                  router.push(
                                      `/project/${project_slug}/${event_slug}`,
                                  ),
                          }
                        : undefined,
                show_terms: !!user && !registered && has_correct_tier,
                ...props_same,
            };
        case EventStage.SnapshotPeriod:
            return {
                has_accent_countdown: isWithin60SecondsBefore(start),
                target_date: start.unix().toString(),
                data: [
                    {
                        label: 'Whitelisted participants',
                        value: commify(num_whitelisted),
                    },
                    {
                        label: 'Total allocation',
                        value: `${total_allocation} USD`,
                    },
                    { label: 'Your max allocation', value: 'TBA' },
                    {
                        label: 'Event network',
                        value: NETWORKS[chainId || DEFAULT_CHAIN_ID]
                            ?.network_name,
                    },
                ],
                primary_button: {
                    text: 'View event',
                    handleClick: () =>
                        router.push(`/project/${project_slug}/${event_slug}`),
                },
                text: 'Guaranteed allocation round opens in',
                ...props_same,
            };
        case EventStage.GaFcfsOpen:
            return {
                data: [
                    {
                        label: 'Whitelisted participants',
                        value: commify(num_whitelisted),
                    },
                    {
                        label: 'Total allocation',
                        value: `${total_allocation} USD`,
                    },
                    {
                        label: 'Your max allocation',
                        value: user
                            ? `${commify(user_allocation.toFixed(2))} USD`
                            : 'N/A',
                    },
                    {
                        label: 'Event network',
                        value: NETWORKS[chainId || DEFAULT_CHAIN_ID]
                            ?.network_name,
                    },
                ],

                raised_stats,
                primary_button: user
                    ? {
                          text: 'Participant now',
                          handleClick: () =>
                              router.push(
                                  `/project/${project_slug}/${event_slug}`,
                              ),
                      }
                    : {
                          text: 'View event',
                          handleClick: () =>
                              router.push(
                                  `/project/${project_slug}/${event_slug}`,
                              ),
                      },

                text: (
                    <>
                        <Media
                            className="text-secondary h-4 w-4 mr-[10px]"
                            variant="clock"
                        />
                        <span>
                            {ga_round_over
                                ? 'FCFS end in'
                                : 'GA round closes in'}
                        </span>
                        <span className="text-primary">
                            &nbsp;{' '}
                            {ga_round_over
                                ? time_to_event_end
                                : time_left_to_ga_end}
                        </span>
                    </>
                ),
                ...props_same,
            };
        case EventStage.Complete:
            return {
                data: [
                    {
                        label: 'Whitelisted participants',
                        value: commify(num_whitelisted),
                    },
                    {
                        label: 'Listing date',
                        value: vesting_start?.format('DD MMMM, YYYY'),
                    },
                    {
                        label: 'Your invested amount',
                        value: user
                            ? `${commify(
                                  formatUnits(
                                      user_investment,
                                      payment_decimals,
                                  ),
                              )} ${payment_symbol}`
                            : 'N/A',
                    },
                    {
                        label: 'Refund duration',
                        value: `${refund_deadline / 86400} ${
                            refund_deadline > 0 ? 'days' : 'day'
                        }`,
                    },
                ],

                button_text: 'View event',
                raised_stats,
                text: has_vesting_start_passed ? undefined : (
                    <>
                        <Media
                            className="text-secondary h-4 w-4 mr-[10px]"
                            variant="clock"
                        />
                        <span>Token available to claim in</span>
                        <span className="text-primary">
                            &nbsp; {time_left_to_vesting_start}
                        </span>
                    </>
                ),
                primary_button: {
                    text:
                        user &&
                        Number(formatUnits(user_investment, payment_decimals)) >
                            0
                            ? 'View my portfolio'
                            : 'View event',
                    handleClick: () =>
                        user &&
                        Number(formatUnits(user_investment, payment_decimals)) >
                            0
                            ? router.push(`/portfolio/${user?.wallet_address}`)
                            : router.push(
                                  `/project/${project_slug}/${event_slug}`,
                              ),
                },
                secondary_button:
                    user &&
                    Number(formatUnits(user_investment, payment_decimals)) > 0
                        ? {
                              text: 'View event',
                              handleClick: () =>
                                  router.push(
                                      `/project/${project_slug}/${event_slug}`,
                                  ),
                          }
                        : undefined,
                ...props_same,
            };
        default:
            break;
    }
};

export const useLatestEventData = (
    projects: Project[],
    num_events = 3,
): {
    next_project_event?: Project;
    following_project_events: Project[];
} => {
    const [next_project_event, setNextProjectEvent] = useState<Project>();

    useEffect(() => {
        getMainEvent().then((res) => {
            setNextProjectEvent(
                projects.find(
                    ({ events }) =>
                        events[0].id.toString() === res?.data[0].value,
                ),
            );
        });
    }, [projects]);

    const valid_projects_secondary_events = projects.filter(
        (p) =>
            (p.events.length &&
                p.events[0].start_date &&
                p.event_status !== EventChipStatus.EventCompleted) ||
            (!p.events.length && !p.announced),
    );

    const following_project_events = valid_projects_secondary_events
        .filter((p) => {
            // make sure its not the same as the mai event card
            if (p.events.length) {
                return p.events[0].id !== next_project_event?.events[0]?.id;
            }
            // include if it has no events and is unannounced
            return !p.announced && !p.events.length;
        })
        .sort((a, b) => {
            const now = dayjs();

            // check if event is ongoing
            const is_ongoing = (project: Project) => {
                const event = project.events[0];
                const start_date = dayjs(Number(event.start_date) * 1000);
                const end_date = getEventEndDate(event);

                if (!event) return false;
                return project.events[0].type === EventType.Crowdfunding
                    ? now.isAfter(start_date) && now.isBefore(end_date)
                    : now.isBefore(start_date) &&
                          event.contract?.address !== null;
            };

            const a_ongoing = a.events.length && is_ongoing(a);
            const b_ongoing = b.events.length && is_ongoing(b);

            if (a_ongoing && !b_ongoing) return -1; // a is ongoing, so it comes first
            if (!a_ongoing && b_ongoing) return 1; // b is ongoing, so it comes first

            if (!a.announced && !a.events.length) return 1; // a goes to the back
            if (!b.announced && !b.events.length) return -1; // b goes to the back

            // Sort upcoming events by start date (newest to oldest)
            return (
                Number(b.events[0]?.start_date || -Infinity) -
                Number(a.events[0]?.start_date || -Infinity)
            );
        })
        .slice(0, num_events);

    return {
        next_project_event,
        following_project_events,
    };
};
