import { useEffect, useState } from 'react';
import { IDOSection, UpcomingEvents } from '@decub8/ui';
import { motion } from 'framer-motion';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';

import { api_client } from '@src/bootstrap/index';
import { Content, ContentSection } from '@src/components';
import {
    CompletedEventsSection,
    MainEventCardContainer,
    SecondaryEventCardsContainer,
} from '@src/components/HomepageComponents';
import { useLatestEventData } from '@src/components/HomepageComponents/MainEventCardContainer/hooks';
import { BASE_IMAGE_URL, CONTENT } from '@src/config';
import { useGlobalContext } from '@src/hooks/useGlobalContext';
import { CONTENT_API } from '@src/services/content';
import { ContentSectionType, EventChipStatus } from '@src/ts/constants';
import { IContentSection } from '@src/ts/interfaces';
import { generateSlug } from '@src/utils/format';
import { getEventStatus } from '@src/utils/getters';

const Home: React.FC<{
    hero_section: IContentSection;
}> = ({ hero_section }) => {
    const [upcoming_events_data, setUpcomingEventsData] = useState([]);

    const router = useRouter();
    const {
        contract_manager,
        projects,
        projects_loaded,
        loadProjects,
        loadContent,
        content_loaded,
        content_sections: { partner_section, learning_section, homepage_faqs },
    } = useGlobalContext();

    useEffect(() => {
        loadProjects();
    }, [loadProjects]);

    useEffect(() => {
        loadContent();
    }, [loadContent]);

    const { next_project_event, following_project_events } =
        useLatestEventData(projects);

    const ido_section_props = {
        title: 'Ready to launch your web3 project?',
        desc: `Unlock the success with ${CONTENT.companyName}. Get in contact and join us and make your vision a reality.`,
        handleClick: () => {
            window.open(CONTENT.apply_for_ido_link, '_blank');
        },
    };

    useEffect(() => {
        const fetchUpcomingEvents = async () => {
            const events_data = await Promise.all(
                projects.map(async ({ announced, events, id, hero, name }) => {
                    // skip projects with no events
                    if (!events || events.length === 0) {
                        return null;
                    }
                    const event_status = await getEventStatus(
                        events[0],
                        announced,
                        contract_manager,
                    );

                    if (
                        event_status === EventChipStatus.ComingSoon &&
                        announced
                    ) {
                        return {
                            image: `${BASE_IMAGE_URL}/${id}/${hero}`,
                            name: events[0].name,
                            type: events[0].type,
                            handleClick: () =>
                                router.push(
                                    `/project/${generateSlug(
                                        name,
                                    )}/${generateSlug(events[0].name)}`,
                                ),
                        };
                    }
                    return null; // Return null if event status is not 'Coming soon'
                }),
            );
            // Filter out null values from the result array
            const filtered_events = events_data.filter(
                (event) => event !== null,
            );
            setUpcomingEventsData(filtered_events);
        };
        fetchUpcomingEvents();
    }, [projects, router, contract_manager]);

    return (
        <Content padding={false} className="mb-[200px]">
            <motion.section
                initial={{ height: '100vh', opacity: 100 }}
                animate={
                    next_project_event ? { height: 'auto', opacity: 1 } : {}
                }
                transition={{ duration: 0.3 }}
            >
                <ContentSection section={hero_section} className="mx-auto" />
            </motion.section>

            <div className={`px-5`}>
                {next_project_event && (
                    <motion.section
                        initial={{ height: '0vh', opacity: 0 }}
                        animate={
                            content_loaded ? { height: 'auto', opacity: 1 } : {}
                        }
                        transition={{ duration: 0.2 }}
                    >
                        <MainEventCardContainer
                            className={'mt-[40px] md:mt-[120px] w-full'}
                            next_project_event={next_project_event}
                        />
                    </motion.section>
                )}
                {following_project_events.length > 0 && (
                    <motion.section
                        initial={{ opacity: 0 }}
                        transition={{ duration: 0.4 }}
                        animate={projects_loaded ? { opacity: 1 } : {}}
                    >
                        <SecondaryEventCardsContainer
                            following_project_events={following_project_events}
                            className={'mt-[40px] md:mt-[120px]'}
                        />
                    </motion.section>
                )}
                {partner_section && (
                    <motion.section
                        initial={{ opacity: 0 }}
                        transition={{ duration: 0.7 }}
                        animate={content_loaded ? { opacity: 1 } : {}}
                    >
                        <ContentSection
                            className={'mt-[40px] md:mt-[120px]'}
                            section={partner_section}
                        />
                    </motion.section>
                )}
                <motion.section
                    initial={{ opacity: 0 }}
                    transition={{ duration: 0.7 }}
                    animate={content_loaded ? { opacity: 1 } : {}}
                >
                    <ContentSection
                        className={'mt-[40px] md:mt-[120px]'}
                        section={learning_section}
                    />
                </motion.section>
                {upcoming_events_data.length > 0 && (
                    <motion.section
                        initial={{ opacity: 0 }}
                        transition={{ duration: 0.7 }}
                        animate={content_loaded ? { opacity: 1 } : {}}
                    >
                        <UpcomingEvents
                            data={upcoming_events_data}
                            className={'mt-[40px] md:mt-[120px]'}
                        />
                    </motion.section>
                )}

                <IDOSection
                    className={'mt-[40px] md:mt-[120px]'}
                    {...ido_section_props}
                />

                <CompletedEventsSection
                    className={'mt-[40px] md:mt-[120px]'}
                    stats_data={hero_section?.data || []}
                />

                <motion.section
                    initial={{ opacity: 0 }}
                    transition={{ duration: 0.7 }}
                    animate={content_loaded ? { opacity: 1 } : {}}
                >
                    <ContentSection
                        className={'mt-[40px] md:mt-[120px]'}
                        section={homepage_faqs}
                    />
                </motion.section>
            </div>
        </Content>
    );
};

export const getServerSideProps: GetServerSideProps = async () => {
    try {
        // Fetch all the required data using the unified query
        const { retrieveHeroSection: hero_section } = await api_client.query<{
            retrieveHeroSection: IContentSection;
        }>({
            query: CONTENT_API.RETRIEVE_HERO_SECTION,
            variables: {
                name: ContentSectionType.HeroSection,
            },
            fetchPolicy: 'network-only',
        });

        return {
            props: {
                hero_section,
            },
        };
    } catch (e) {
        return {
            props: {
                projects: [],
            },
        };
    }
};

export default Home;
